import React, { useState } from 'react';
import { func, string, object, shape, oneOfType, bool } from 'prop-types';
import { List, ListItem, listItemStackSpacing } from '@nutkit/component-list';
import Link from '@nutkit/component-link';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import { Declaration, DeclarationPersonalInfo } from '@nm-portfolio-lib-web/common/components';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { iconColors } from '@nutkit/component-icon';
import { Trans } from '@nm-utils-lib-web/translations';
import { useGetCustomerPersonalInformation } from '@nm-customer/common/hooks';
import useGetTaxYearPeriod from '@nm-portfolio-lib-web/common/hooks/useGetTaxYearPeriod';
import { Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';

import { trackAcceptedDeclaration } from '../../../../tracking/events/sisaDeclaration.js'
import { TERMS_AND_CONDITIONS, ISA_TERMS_AND_CONDITIONS } from '../../constants/URLConstants';

const TRANSLATION_NAMESPACE = 'start.declaration.latest.sisa';

const DeclarationSISA = ({ t, 'data-qa': dataQa, journeyNavigation, backBtn, userUuid, intent, potUuid }) => {
  const isaSettingsVariant = useVariant(Flags.ISA_SETTINGS_TEST);
  const VARIANT_NAMESPACE =
    isaSettingsVariant?.name === Variants.VARIANT_B
      ? `${TRANSLATION_NAMESPACE}.${isaSettingsVariant.name}`
      : TRANSLATION_NAMESPACE;
  const [showError, setError] = useState(false);
  const { isLoading, data: customerPersonalInfo } = useGetCustomerPersonalInformation({
    customerUuid: userUuid,
    onError: () => setError(true)
  });
  const { taxYear, isLoading: isLoadingTaxYear } = useGetTaxYearPeriod({
    customerUuid: userUuid
  });

  const acceptAndTrackDeclaration = () => {

    trackAcceptedDeclaration({
      potUuid,
      intent
    })

    journeyNavigation.continueButton.onClick();
  }  

  return (
    <Declaration
      checkboxText={t(`${TRANSLATION_NAMESPACE}.tickTheBoxAndDeclare`)}
      data-qa={dataQa}
      journeyNavigation={{ ...journeyNavigation, backButton: backBtn ? journeyNavigation.backButton : undefined }}
      onAcceptedDeclaration={acceptAndTrackDeclaration}
      showError={showError}
      showLoading={isLoading || isLoadingTaxYear}
      titleText={t(`${VARIANT_NAMESPACE}.title`)}
      userUuid={userUuid}
      wrapperType={wrapperTypes.SISA}
    >
      <Heading data-qa={`${dataQa}-heading`} level={headingLevels.TWO}>
        {t(`${TRANSLATION_NAMESPACE}.heading`)}
      </Heading>
      <DeclarationPersonalInfo
        customerPersonalInfo={customerPersonalInfo}
        translationNamespace={`${TRANSLATION_NAMESPACE}.personalDetails`}
      />
      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.declarations.heading`)}</Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
      <List bulletColor={iconColors.SUCCESS} space={listItemStackSpacing.SM}>
        <ListItem>
          <Trans components={[taxYear]} i18nKey={`${TRANSLATION_NAMESPACE}.declarations.subscriptionApply`} />
        </ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.declarations.applyToTransfer`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.declarations.ageAndLocation`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.declarations.ownership`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.declarations.subscribedWrappers`)}</ListItem>
        <ListItem>
          <Trans
            components={[
              <Link href={TERMS_AND_CONDITIONS} isExternal>
                {''}
              </Link>,
              <Link href={ISA_TERMS_AND_CONDITIONS} isExternal>
                {''}
              </Link>
            ]}
            i18nKey={`${TRANSLATION_NAMESPACE}.declarations.agreement`}
          />
        </ListItem>
      </List>
      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.authorisations.heading`)}</Heading>
      <List bulletColor={iconColors.SUCCESS} space={listItemStackSpacing.SM}>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.authorisations.holdSubscriptions`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.authorisations.claimTax`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.authorisations.sendProceeds`)}</ListItem>
        <ListItem>{t(`${TRANSLATION_NAMESPACE}.authorisations.completionOfTheTransfer`)}</ListItem>
      </List>
    </Declaration>
  );
};

DeclarationSISA.propTypes = {
  'data-qa': string,
  intent: string.isRequired,
  potUuid: string.isRequired,
  journeyNavigation: shape({
    continueButton: object,
    backButton: object,
    showErrorMessage: oneOfType([bool, string])
  }).isRequired,
  t: func.isRequired,
  userUuid: string.isRequired,
  backBtn: bool
};

DeclarationSISA.defaultProps = {
  backBtn: true,
  'data-qa': 'portfolio-sisa-declaration'
};

export default DeclarationSISA;
